@use "settings/settings" as *;

:root {
  --color-bg-bits: #{$neutral-xxlight};
  --color-bg-light: #{$neutral-xxxlight};
  --color-bg-mid: #{$neutral-xxlight};
  --color-bg-dark: #{$neutral-light};
  --color-bg-dashboard-newsfeed-item: #{$blue-xlight};
  --color-bg-progress-bar: #{$neutral-light};
  --color-bg-list-item-hover: #{$neutral-xlight};
  --color-bg-input: #{$neutral-xxxlight};
  --color-bg-input-disabled: #{$neutral-xlight};
  --color-bg-give-get-banner: #{$blue-xlight};
  /* stylelint-disable-next-line color-no-hex */
  --color-bg-loading-primary: #d8d8d8;
  /* stylelint-disable-next-line color-no-hex */
  --color-bg-loading-secondary: #eee;
  --color-bg-message-bubble-other-user: #{$neutral-xlight};
  --color-bg-messaging-reply: #{$neutral-xlight};
  --color-bg-file-display: #{$neutral-xlight};

  --color-hover-light: #{$neutral-xxlight};
  --color-hover-mid: #{$neutral-xlight};
  --color-hover-dark: #{$neutral-dark};

  // Borders
  --color-navigation-border: none;
  --color-border-cards: #{$neutral-xlight};
  --color-border-light: #{$neutral-light};

  // Text Color
  --color-foreground: #{$neutral-xxdark};
  --color-light: #{$color-light};
  --color-dark: #{$color-dark};
  --color-primary: #{$color-primary};
  --color-error: #{$color-error};
  --color-success: #{$color-success};
  --color-warning: #{$color-warning};

  // Text Size
  --text-size-xmegahero: #{$font-size-xmegahero};
  --text-size-megahero: #{$font-size-megahero};
  --text-size-hero: #{$font-size-hero};
  --text-size-hero-compact: #{$font-size-heroCompact};
  --text-size-heading1: #{$font-size-heading1};
  --text-size-heading2: #{$font-size-heading2};
  --text-size-heading3: #{$font-size-heading3};
  --text-size-heading4: #{$font-size-heading4};
  --text-size-landing: #{$font-size-landing};
  --text-size-ui: #{$font-size-ui};
  --text-size-detail: #{$font-size-detail};
  --text-size-tiny: #{$font-size-tiny};

  // Line Height
  --line-height-xmegahero: #{$line-height-xmegahero};
  --line-height-megahero: #{$line-height-megahero};
  --line-height-hero: #{$line-height-hero};
  --line-height-hero-compact: #{$line-height-heroCompact};
  --line-height-heading1: #{$line-height-heading1};
  --line-height-heading2: #{$line-height-heading2};
  --line-height-heading3: #{$line-height-heading3};
  --line-height-heading4: #{$line-height-heading4};
  --line-height-landing: #{$line-height-landing};
  --line-height-ui: #{$line-height-ui};
  --line-height-detail: #{$line-height-detail};
  --line-height-tiny: #{$line-height-tiny};

  // Links
  --color-link: #{$blue-dark};
  --color-link-hover: #{$blue};

  --color-fab-icon: #{$neutral-xxlight};
  --color-fab-bg: radial-gradient(ellipse at right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0.2) 80%, rgba(255, 255, 255, 0.1) 90%, rgba(255, 255, 255, 0) 100%);
  --color-table-highlight: #{$table-row-highlight-color};

  // Buttons Background
  --color-button-bg-default: #{$neutral-xxlight};
  --color-button-bg-disabled: #{$neutral-xlight};

  // Buttons Text
  --color-button-text-trans-dark: #{$neutral-dark};
  --color-button-text-default: #{$neutral-dark};
  --color-button-text-disabled: #{$neutral-light};
  --color-button-filter-selected: #{$neutral-xxdark};

  // Buttons Border
  --color-button-border-default: #{$neutral-light};
  --color-button-border-disabled: #{$neutral-xxlight};

  // Base Placeholder
  --color-placeholder-text: #{$neutral};

  // Dropdown Filter
  --color-dropdown-filter-applied: #{$blue-xlight};

  // User Avatar
  --color-border-avatar: #{$neutral-xlight};
  --color-border-light-avatar: #{$neutral-xxlight};

  // Give Page
  --color-give-bg-before-cover: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  --color-give-bg-after-cover: linear-gradient(to top, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);

  // Post Project
  --color-pjp-header-border-gradient: #{linear-gradient(to bottom, $neutral-light, transparent)};
  --color-pjp-bg-description-entropy: #{$neutral-xlight};

  // Profile Page
  --color-bg-profile-page: #{$neutral-xxxlight};
  --color-fade-profile-page-filter: #{linear-gradient(to bottom, rgba($neutral-xxxlight, 0) 0%, $neutral-xxxlight 60%)};

  --color-primary-green: #{$green-xdark};

  // Standard level 1 shadow that also adds a glow in dark mode
  --box-shadow-glow: #{0 0 4px 0 $card-shadow-level-1, 0 2px 4px 0 $card-shadow-level-1-secondary};

  // Callout border
  --color-callout-border: unset;

  // Nav Menu
  --color-bg-menu-left-side: #{$neutral-xxxlight};
  --color-bg-menu-right-side: #{$neutral-xxlight};
  --color-bg-menu-nav-item: #{$neutral-xxxlight};
  --color-bg-menu-nav-item-active-dobleColumn: #{$neutral-xxxlight};
  --color-bg-menu-nav-item-active: #{$neutral-xxlight};
  --color-bg-menu-nav-item-hover: #{rgba($neutral-xlight, 0.8)};
  --color-border-menu-nav-item: #{$neutral-xlight};
  --color-bg-menu-card-hover: #{rgba($neutral-xxxlight, 0.8)};
  --color-bg-menu-groups-card: #{$neutral-xxxlight};

  // carousel Buttons
  --color-carousel-bg-button: #{rgba($neutral-xdark, 0.3)};
  --color-carousel-bg-button-hover: #{rgba($neutral-xdark, 0.6)};

  // Messaging redesign
  --color-bg-messaging-page: #{$blue-xlight};
}

/* stylelint-disable-next-line selector-class-pattern */
.dark {
  --color-bg-bits: #{$neutral-xxdark};
  --color-bg-light: #{$neutral-xdark};
  --color-bg-mid: #{$neutral-xxdark};
  --color-bg-dark: #{$neutral-xxdark};
  --color-bg-dashboard-newsfeed-item: #{$blue-xxdark};
  --color-bg-progress-bar: #{$neutral-xxxlight};
  --color-bg-input-disabled: #{$neutral-dark};
  --color-bg-input: #{$neutral-xxdark};
  --color-bg-give-get-banner: #{$blue-xxdark};
  /* stylelint-disable-next-line color-no-hex */
  --color-bg-loading-primary: #4d4d4d;
  /* stylelint-disable-next-line color-no-hex */
  --color-bg-loading-secondary: #666;
  --color-bg-message-bubble-other-user: #{$neutral};
  --color-bg-messaging-reply: #{$neutral-dark};
  --color-bg-file-display: #{$neutral-dark};

  --color-hover-light: #{$neutral-dark};
  --color-hover-mid: #{$neutral-dark};
  --color-hover-dark: #{$neutral-dark};

  // Borders
  --color-navigation-border: #{$neutral-dark};
  --color-border-cards: #{$neutral-dark};
  --color-border-light: #{$neutral-dark};

  --color-foreground: #{$neutral-xlight};

  // Links
  --color-link: #{$blue};
  --color-link-hover: #{$blue-light};

  --color-fab-icon: #{$neutral-xxdark};
  --color-fab-bg: radial-gradient(ellipse at right, rgba(18, 21, 27, 1) 0%, rgba(18, 21, 27, 1) 50%, rgba(18, 21, 27, 0.8) 60%, rgba(18, 21, 27, 0.3) 70%, rgba(18, 21, 27, 0.2) 80%, rgba(18, 21, 27, 0.1) 90%, rgba(18, 21, 27, 0) 100%);
  --color-table-highlight: #{$table-row-highlight-color-dark};

  // Buttons Background
  --color-button-bg-default: #{$neutral};
  --color-button-bg-disabled: #{$neutral};

  // Buttons Text
  --color-button-text-trans-dark: #{$neutral-xxlight};
  --color-button-text-default: #{$neutral-xxxlight};
  --color-button-text-disabled: #{$neutral-dark};
  --color-button-filter-selected: #{$blue-dark};

  // Buttons Border
  --color-button-border-default: #{$neutral};
  --color-button-border-disabled: #{$neutral};

  // Base Placeholder
  --color-placeholder-text: #{$neutral};

  // Dropdown Filter
  --color-dropdown-filter-applied: #{$blue-xxdark};

  // User Avatar
  --color-border-avatar: #{$neutral};
  --color-border-light-avatar: #{$neutral};

  // Give Page
  --color-give-bg-before-cover: linear-gradient(to bottom, rgb(18, 21, 27) 0%, rgba(61, 74, 93, 0) 100%);
  --color-give-bg-after-cover: linear-gradient(to top, rgb(18, 21, 27) 0%, rgba(61, 74, 93, 0) 100%);

  // Post Project
  --color-pjp-header-border-gradient: #{linear-gradient(to bottom, $neutral-xdark, transparent)};
  --color-pjp-bg-description-entropy: #{$neutral-xdark};

  // Profile Page
  --color-bg-profile-page: #{$neutral-xxdark};
  --color-fade-profile-page-filter: #{linear-gradient(to bottom, rgba($neutral-xdark, 0) 0%, $neutral-xdark 60%)};

  --color-primary-green: #{$green};

  // Standard level 1 shadow that also adds a glow in dark mode
  --box-shadow-glow: #{0 0 4px rgba($neutral-xxxlight, 0.4)};

  // Callout border
  --color-callout-border: #{1px solid $neutral-dark};

  // Nav Menu
  --color-bg-menu-left-side: #{$neutral-xxdark};
  --color-bg-menu-right-side: #{$neutral-xdark};
  --color-bg-menu-nav-item: #{$neutral-xxdark};
  --color-bg-menu-nav-item-active-dobleColumn: #{$neutral-xdark};
  --color-bg-menu-nav-item-active: #{$neutral-xdark};
  --color-bg-menu-nav-item-hover: #{rgba($neutral-xdark, 0.8)};
  --color-border-menu-nav-item: #{$neutral-xxdark};
  --color-bg-menu-card-hover: #{rgba($neutral-xxdark, 0.8)};
  --color-bg-menu-groups-card: #{$neutral-xxdark};

  // carousel Buttons
  --color-carousel-bg-button: #{rgba($neutral-light, 0.3)};
  --color-carousel-bg-button-hover: #{rgba($neutral-light, 0.6)};

  // Messaging redesign
  --color-bg-messaging-page: #{$neutral-xxdark};
}
