@tailwind base;
@tailwind components;
@tailwind utilities;

/* stylelint-disable selector-max-type */
p {
  color: var(--color-foreground);
  font-size: var(--text-size-ui);
  line-height: var(--line-height-ui);
}
/* stylelint-enable selector-max-type */
